$(document).ready(function () {

	AOS.init();

});


///////////////////////////////////////////////////////////////////////////
$(document).ready((function () {
	$('.navbar-toggler').click((function (e) {
		setTimeout(function(){
			var header = $('.navbar-toggler').hasClass("collapsed");
			if(header){
				$('.header').removeClass('mobMenu');
				$('.hamburger').removeClass("is-active");
			}
			else {
				$('.header').addClass('mobMenu');
				$('.hamburger').addClass("is-active");
			}
		},1);
	}));

	if ($(window).width() > 769) {
		$('.megamenu-li').hover((function () {
			$(this).find('.megamenu').first().stop(true, true).delay(250).slideDown();
		}), (function () {
			$(this).find('.megamenu').first().stop(true, true).delay(100).slideUp();
		}));

		$('.megamenu-li > a').click((function () {
			location.href = this.href;
		}));
	}

	//category image check
	$('.checkbox').click((function () {
		$(this).find(":checkbox").prop("checked", !$(this).find(":checkbox").prop("checked"));
	}));

	//////////////////////////////////
	if($( window ).width() < 768){
			$('#select').click((function () {
				// alert();
				$('#manpowerMyTab').show();
			}));
			$('#manpowerMyTab a').click((function (e) {
				$('#select').text($(this).text());
				$('#manpowerMyTab').hide();
				$(this).addClass('current');
				e.preventDefault();
			}));
	}
}));


///////////////////////////////////////////////////////////////////////////////
// var slider = new Swiper ('.gallery-slider', {
// 	slidesPerView: 1,
// 	loop: true,
// 	slideToClickedSlide: true,
// navigation: {
// 		nextEl: '.swiper-button-next',
// 		prevEl: '.swiper-button-prev',
// },
// });
// var thumbs = new Swiper ('.gallery-thumbs', {
// 	slidesPerView: 2,
// 	spaceBetween: 10,
// 	loop: false,
// 	slideToClickedSlide: true,
// 	navigation: {
// 		nextEl: '.swiper-button-next',
// 		prevEl: '.swiper-button-prev',
// },
// });


//slider.params.control = thumbs;
//thumbs.params.control = slider;

// slider.controller.control = thumbs;
// thumbs.controller.control = slider;
///////////////////////////////////////////////////////////////////////////////
$(document).ready(function () {
	$('.navbar-nav a').on("click", function () {
		//removing the previous selected menu state
		$('.navbar-nav').find('li.active').removeClass('active');
		//adding the state for this parent menu
		$(this).parents("li").addClass('active');

	});
	$('#getaquote li a').on("click", function () {
		//removing the previous selected menu state
		$('#getaquote').find('li.active').removeClass('active');
		//adding the state for this parent menu
		$(this).parents("li").addClass('active');

	});

	// $(" li a.active").parent().addClass("active");
});
/////////////////////////////////////////////////////////////////////////////////

$(document).ready(function () {
	$(".megamenu").on("click", function (e) {
		$(this).toggleClass('show');
		e.stopPropagation();
	});


	//////////////////////////////////////////////////////////////////////////////////
	var swiper = new Swiper(".projectSwiper", {
		slidesPerView: 4,
		spaceBetween: 0,
		slidesPerGroup: 4,
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			clickable: true,

		},
		// navigation: { 
		// 	nextEl: ".swiper-button-next",
		// 	prevEl: ".swiper-button-prev",
		// },
		breakpoints: {

			1000: {
				slidesPerView: 4
			},
			700: {
				slidesPerView: 3
			},
			500: {
				slidesPerView: 2
			},
			320: {
				spaceBetween: 0,
				slidesPerGroup: 1,
				slidesPerView: 1,
				spaceBetween: 0,
			},
		}
	});

	//////////////////////////////////////////////////////////////////////////////////
	var swiper = new Swiper(".bannerSwiper", {
		slidesPerView: 1,
		spaceBetween: 0,
		slidesPerGroup: 1,
		loop: true,
		autoplay: true,
		loopFillGroupWithBlank: true,
		// pagination: {
		// 	el: '.swiper-pagination',
		// 	clickable: true,

		// },
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});
	//////////////////////////////////////////////////////////////////////////////////
	var swiper = new Swiper(".bannerThumbSwiper", {

		slidesPerView: 2,
		slidesPerGroup: 1,
		spaceBetween: 15,
		autoplay: true,
		loop: true,
		// pagination: {
		// 	el: '.swiper-pagination',
		// 	clickable: true,

		// },
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {

			575: {
				slidesPerView: 2,
			},
			300: {
				slidesPerView: 1,
				slidesPerGroup: 1,

			},
		}

	});


	///////////////////////////////////////////////////////////////////////////////////
	var swiper = new Swiper(".clientSwiper", {
		slidesPerView: 5,
		centeredSlides: true,
		centeredSlidesBounds: true,
		spaceBetween: 0,
		slidesPerGroup: 1,
		loop: true,
		autoplay: true,
		loopFillGroupWithBlank: true,

		// pagination: {
		// 	el: ".swiper-pagination",
		// 	clickable: true,
		// },
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			992: {
				slidesPerView: 5,
			},
			575: {
				slidesPerView: 3,
			},
			300: {
				slidesPerView: 1
			},
		}

	});

	///////////////////////////////////////////////////////////////////////////////////
	var swiper = new Swiper(".companyGroup", {
		slidesPerView: 4,
		// centeredSlides: true,
		// centeredSlidesBounds: true,
		spaceBetween: 10,
		slidesPerGroup: 1,
		loop: true,
		autoplay: true,
		loopFillGroupWithBlank: true,

		// pagination: {
		// 	el: ".swiper-pagination",
		// 	clickable: true,
		// },
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			992: {
				slidesPerView: 4,
				centeredSlides: false,
			},
			768: {
				slidesPerView: 3,
				centeredSlides: false,
			},
			575: {
				slidesPerView: 2,
				centeredSlides: false,
			},
			300: {
				slidesPerView: 1,
				centeredSlides: false,
			},
		}

	});
	///////////////////////////////////////////////////////////////////////////////////
	var swiper = new Swiper(".topClientSwiper", {
		slidesPerView: 5,
		centeredSlides: true,
		centeredSlidesBounds: true,
		spaceBetween: 0,
		slidesPerGroup: 1,
		loop: true,
		autoplay: true,
		loopFillGroupWithBlank: true,

		// pagination: {
		// 	el: ".swiper-pagination",
		// 	clickable: true,
		// },
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			992: {
				slidesPerView: 5,
			},
			575: {
				slidesPerView: 3,
			},
			300: {
				slidesPerView: 1
			},
		}

	});
	/////////////////////////////////////////////////////////////////////////////////
	/////////////////////////////////////////////////////////////////////////////////

	var swiper = new Swiper(".aboutClientSwiper", {
		slidesPerView: 5,
		centeredSlides: true,
		centeredSlidesBounds: true,
		spaceBetween: 0,
		slidesPerGroup: 1,
		loop: true,
		autoplay: true,
		loopFillGroupWithBlank: true,
		breakpoints: {
			992: {
				slidesPerView: 5,
			},
			575: {
				slidesPerView: 3,
			},
			300: {
				slidesPerView: 1
			},
		}
		// pagination: {
		//   el: ".swiper-pagination",
		//   clickable: true,
		// },
		// navigation: {
		//   nextEl: ".swiper-button-next",
		//   prevEl: ".swiper-button-prev",
		// },

	});
	///////////////////////////////////////////////////////////////////////////////////
	var swiper = new Swiper(".accreditationsSwiper", {
		slidesPerView: 4,
		spaceBetween: 5,
		slidesPerGroup: 1,
		loop: true,
		autoplay: true,
		loopFillGroupWithBlank: true,
		// pagination: {
		//   el: ".swiper-pagination",
		//   clickable: true,
		// },
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		breakpoints: {
			992: {
				slidesPerView: 4,
			},
			700: {
				slidesPerView: 3,
			},
			500: {
				slidesPerView: 2,
			},
			300: {
				slidesPerView: 1
			},
		}

	});
	///////////////////////////////////////////////////////////////////////////////////
	var counters = $(".count");
	var countersQuantity = counters.length;
	var counter = [];
	var startcount = [];
	var i = 0;
	var j = 0;
	for (i = 0; i < countersQuantity; i++) {
		counter[i] = parseInt(counters[i].innerHTML);
		startcount[i] = counter[i];
	}

	var count = function count(start, value, id) {
		var localStart = start;
		setInterval((function () {
			if (localStart < value) {
				localStart++;
				counters[id].innerHTML = localStart;
			}
		}), 40);
	};

	startcount.sort(function(a, b) {
	  return a - b;
	});

	for (j = 0; j < countersQuantity; j++) {
		var start = counter[j] - startcount[0];
		count(start, counter[j], j);
	}



});

///////////////////////////////
// Cache selectors
var lastId,
	topMenu = $("#top-menu"),
	topMenuHeight = topMenu.outerHeight() + 15,
	// All list items
	menuItems = topMenu.find("a"),
	// Anchors corresponding to menu items
	scrollItems = menuItems.map(function () {
		var item = $($(this).attr("href"));
		if (item.length) {
			return item;
		}
	});

// Bind click handler to menu items
// so we can get a fancy scroll animation
menuItems.click(function (e) {
	var href = $(this).attr("href"),
		offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;
	$('html, body').stop().animate({
		scrollTop: offsetTop
	}, 300);
	e.preventDefault();
});

// Bind to scroll
$(window).scroll(function () {
	// Get container scroll position
	var fromTop = $(this).scrollTop() + topMenuHeight;

	// Get id of current scroll item
	var cur = scrollItems.map(function () {
		if ($(this).offset().top < fromTop)
			return this;
	});
	// Get the id of the current element
	cur = cur[cur.length - 1];
	var id = cur && cur.length ? cur[0].id : "";

	if (lastId !== id) {
		lastId = id;
		// Set/remove active class
		menuItems
			.parent().removeClass("active")
			.end().filter("[href='#" + id + "']").parent().addClass("active");
	}
});



//////////////////////////////////////////////////////////////////////


var swiper = new Swiper('.project-swiper', {
	pagination: '.swiper-pagination',
	// slidesPerView: 1.5,
	// centeredSlides: true,
	// loop: true,
	// spaceBetween: 10,



	slidesPerView: 1.5,
	spaceBetween: 1,
	centeredSlides: true,
	freeMode: true,
	loop: true,
	// pagination: {
	// 	el: ".swiper-pagination",
	// 	clickable: true
	// },
	autoplay: {
		delay: 4000,
		disableOnInteraction: false
	},
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev"
	},
	breakpoints: {
		1200: {
			slidesPerView: 1.5,
		},
		1199: {
			slidesPerView: 1,
			centeredSlides: false,
		},
		300: {
			slidesPerView: 1
		},
	}



});
///////////////////////Button Count////////////////////////////////
var num;
$(document).on('click', '.button-count:first-child', function () {
	num = parseInt($(this).parent().find('input:text').val());
	if (num > 0) {
		$(this).parent().find('input:text').val(num - 1);
	}
	if (num == 1) {
		$(this).parent().find('.button-count:first-child').prop('disabled', true);
	}
	if (num == 100) {
		$(this).parent().find('.button-count:last-child').prop('disabled', false);
	}
});

$(document).on('click', '.button-count:last-child', function () {
	num = parseInt($(this).parent().find('input:text').val());
	if (num < 100) {
		$(this).parent().find('input:text').val(num + 1);
	}
	if (num > 0) {
		$(this).parent().find('.button-count:first-child').prop('disabled', false);
	}
	if (num == 100) {
		$(this).parent().find('.button-count:last-child').prop('disabled', true);
	}
});
/////////////////////Select File Name//////////////////////
var input = document.getElementById('file-upload');
var infoArea = document.getElementById('file-upload-filename');
if(input)
	input.addEventListener('change', showFileName);

function showFileName(event) {
	var input = event.srcElement;
	var fileName = input.files[0].name;
	infoArea.textContent = 'File name: ' + fileName;
}

var inputpop = document.getElementById('file-upload-popup');
var infoAreapop = document.getElementById('file-upload-filename-popup');
if(input)
	inputpop.addEventListener('change', showFileNamepop);

function showFileNamepop(event) {
	var inputpop = event.srcElement;
	var fileNamepop = inputpop.files[0].name;
	infoAreapop.textContent = 'File name: ' + fileNamepop;
} 



////////////////////////////////////////////

